"use strict";
exports.__esModule = true;
exports.getPageTranslations = exports.getPageChildren = exports.getPage = void 0;
var client_1 = require("../api/client");
var wagtailConfig_1 = require("../config/wagtailConfig");
exports.getPage = function (id) {
    var url = "" + wagtailConfig_1.ADMIN_API.PAGES + id + "/";
    return client_1.get(url);
};
exports.getPageChildren = function (id, options) {
    if (options === void 0) { options = {}; }
    var url = wagtailConfig_1.ADMIN_API.PAGES + "?child_of=" + id + "&for_explorer=1";
    if (options.fields) {
        url += "&fields=parent,translations," + window.encodeURIComponent(options.fields.join(','));
    }
    else {
        url += '&fields=parent,translations';
    }
    if (options.onlyWithChildren) {
        url += '&has_children=1';
    }
    if (options.offset) {
        url += "&offset=" + options.offset;
    }
    url += wagtailConfig_1.ADMIN_API.EXTRA_CHILDREN_PARAMETERS;
    return client_1.get(url);
};
exports.getPageTranslations = function (id, options) {
    if (options === void 0) { options = {}; }
    var url = wagtailConfig_1.ADMIN_API.PAGES + "?translation_of=" + id + "&limit=20";
    if (options.fields) {
        url += "&fields=parent," + global.encodeURIComponent(options.fields.join(','));
    }
    else {
        url += '&fields=parent';
    }
    if (options.onlyWithChildren) {
        url += '&has_children=1';
    }
    return client_1.get(url);
};
